.GameBoardContainer {
  margin: 0 auto; 
  width: 720px;
  position: relative;
  perspective: 900px;
  transition: opacity 1s;
}

.GameBoardContainer.hidden {
  opacity: 0;
}

.GameBoard {
  height: 1375px;
  transform-style: preserve-3d;
}

.BaseBoard {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.BaseBoard img {
  width: 100%;
}

.Track {
  position: absolute;
  top: 40.75%;
  left: 0;
  width: 100%;
  height: calc(100% - 40.75%);
}

.LongTermFirst, .LongTermLast {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  width: 110px;
  height: 165px;
  position: absolute;
  opacity: 0;

  transition: opacity 0.25s;
  transform: translate3d(-50%, -50%, 1px);
}

/* .MyTurn is redundant now but /shrug */
.MyTurn .TopButtons.enabled .LongTermFirst:hover,
.MyTurn .TopButtons.enabled .LongTermLast:hover {
  opacity: 1;
}

.LongTermFirst {
  top: 7.5%;
  left: 9.9%;
}

.LongTermLast {
  top: 7.5%;
  left: 89.4%;
}

.Spot {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 72pt;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 10px;

  transition: background-color 0.25s;
  transform: translateZ(1px);
}

.MyTurn .Spot.enabled:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.Spot img {
  position: absolute;
  /* this is based on the space size so the cards aren't actually totally consistent but that's ok */
  width: 75%;
  transform: translateZ(10px);
  border-radius: 10px;
  transition: opacity 0.25s;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.Spot img.hidden {
  opacity: 0;
}

.Camels {
  width: 100%;
  height: 100%;
}

.Camel {
  position: absolute;
  width: 12%;
  transform: translate3d(-50%, -50%, 26px) rotateX(-70deg);
  transition: top 1s, left 1s;
  transform-style: preserve-3d;
}

.camel-y-offset {
  transition: transform 1s;
}

.Camel img {
  width: 100%;
}

.Die {
  position: absolute;
  font-size: 64px;
  font-weight: bold;
  transform: translate3d(-50%, -50%, 50px) rotateX(-70deg);
  transition: top 1s, left 1s, font-size 1s;
  text-shadow: 0px 0px 10px black;
}

.Dice {
  width: 100%;
  height: 100%;
}

.Die.blue {
  top: 38%;
  left: 33%;
  color: #4faac9;
}

.Die.green {
  top: 34%;
  left: 39%;
  color: #789c2e;
}

.Die.orange {
  top: 32%;
  left: 49%;
  color: #e29932;
}

.Die.yellow {
  top: 34%;
  left: 58.5%;
  color: #ddcd22;
}

.Die.white {
  top: 38%;
  left: 64%;
  /* don't want it confused with yellow */
  /* color: #dbdabc; */
  color: white;
}

.Die.center {
  top: 73%;
  left: 50%;
  font-size: 108px;
}

.BetCards {
  width: 100%;
  height: 100%;
}

.BetCardStack {
  position: absolute;
  transform-style: preserve-3d;
}

.BetCardStack.blue {
  top: 33.5%;
  left: 15.75%;
  transform: rotateZ(-61deg);
}

.BetCardStack.green {
  top: 25.35%;
  left: 28.3%;
  transform: rotateZ(-38deg);
}

.BetCardStack.orange {
  top: 21.25%;
  left: 49.5%;
}

.BetCardStack.yellow {
  top: 25.35%;
  left: 70.7%;
  transform: rotateZ(38.5deg);
}

.BetCardStack.white {
  top: 33.4%;
  left: 83.75%;
  transform: rotateZ(60.5deg);
}

.BetCardStack img {
  position: absolute;
  width: 110px;
  transition: opacity 0.5s, transform 0.25s;
}

.BetCardStack img.hidden {
  opacity: 0;
}

.BetCardStack img.bet-value-2 {
  transform: translate3d(-50%, -50%, 5px);
}

.BetCardStack img.bet-value-3 {
  transform: translate3d(-50%, -50%, 10px);
}

.BetCardStack img.bet-value-5 {
  transform: translate3d(-50%, -50%, 15px);
}

.MyTurn .BetCardStack:hover img.bet-value-2 {
  transform: translate3d(-50%, -50%, 10px);
}

.MyTurn .BetCardStack:hover img.bet-value-3 {
  transform: translate3d(-50%, -50%, 20px);
}

.MyTurn .BetCardStack:hover img.bet-value-5 {
  transform: translate3d(-50%, -50%, 30px);
}

.Pyramid {
  position: absolute;
  width: 36%;
  height: 36%;
  top: 27%;
  left: 32%;
  pointer-events: none;
}

.Pyramid .hidden-base {
  /* background-color: black; */
  position: absolute;
  width: 100%;
  height: 120%;
  pointer-events: auto;
  transform: translateZ(1px);
}

.Pyramid img {
  width: 100%;
  position: absolute;
  transform-origin: bottom center;
  /* border: 10px solid black;
  opacity: 0.2; */
  /* honestly could not tell you why but this renders in front of the camels if I have it at 100% */
  opacity: 0.9999;
  transition: transform 0.25s, opacity 3s ease-in-out;
}

.Pyramid.hidden img {
  opacity: 0;
}

.Pyramid .front {
  transform: translateY(25px) translateY(50%) rotateX(-51deg);
}

.MyTurn .Pyramid.hovered .front {
  transform: translateZ(40px) translateY(25px) translateY(50%) rotateX(-51deg);
}

.Pyramid .left {
  transform: translateX(-50%) rotateZ(90deg) rotateX(-51deg);
}

.MyTurn .Pyramid.hovered .left {
  transform: translateZ(40px) translateX(-50%) rotateZ(90deg) rotateX(-51deg);
}

.Pyramid .right {
  transform: translateX(50%) rotateZ(-90deg) rotateX(-51deg);
}

.MyTurn .Pyramid.hovered .right {
  transform: translateZ(40px) translateX(50%) rotateZ(-90deg) rotateX(-51deg);
}

/* don't think this one shows anymore now that the pyramid is taller than it used to be */
/* .Pyramid .back {
  transform: translateY(-50%) rotateX(-129deg);
  transition: transform 0.25s;
}

.Pyramid:hover .back {
  transform: translateZ(10px) translateY(-50%) rotateX(-129deg);
} */

.spot-15 {
  top: 0;
  left: 0;
  width: 18.5%;
  height: 20.5%;
}

.spot-16 {
  top: 0;
  left: 18.5%;
  width: 20.75%;
  height: 20.5%;
}

.spot-1 {
  top: 0;
  left: 39.25%;
  width: 21%;
  height: 20%;
}

.spot-2 {
  top: 0;
  left: 60.25%;
  width: 21%;
  height: 20%;
}

.spot-3 {
  top: 0;
  left: 81.25%;
  width: 18.75%;
  height: 20%;
}

.spot-4 {
  top: 20%;
  left: 81.25%;
  width: 18.75%;
  height: 19.5%;
}

.spot-5 {
  top: 39.5%;
  left: 81.25%;
  width: 18.75%;
  height: 19.5%;
}

.spot-6 {
  top: 59%;
  left: 81.25%;
  width: 18.75%;
  height: 20%;
}

.spot-7 {
  top: 79%;
  left: 81%;
  width: 19%;
  height: 21%;
}

.spot-8 {
  top: 79%;
  left: 60.5%;
  width: 20.5%;
  height: 21%;
}

.spot-9 {
  top: 79%;
  left: 38.5%;
  width: 22%;
  height: 21%;
}

.spot-10 {
  top: 79%;
  left: 18.5%;
  width: 20%;
  height: 21%;
}

.spot-11 {
  top: 79%;
  left: 0;
  width: 18.5%;
  height: 21%;
}

.spot-12 {
  top: 59.75%;
  left: 0;
  width: 18.5%;
  height: 19.25%;
}

.spot-13 {
  top: 39.75%;
  left: 0;
  width: 18.5%;
  height: 20%;
}

.spot-14 {
  top: 20.5%;
  left: 0;
  width: 18.5%;
  height: 19.25%;
}

.camel-spot-15 {
  top: calc(0% + 20.5% / 2);
  left: calc(0% + 18.5% / 2);
}

.camel-spot-16 {
  top: calc(0% + 20.5% / 2);
  left: calc(18.5% + 20.75% / 2);
}

/* pseudo-spot */
.camel-spot-17 {
  top: calc(0% + 20% / 2);
  left: calc(39.25% + 21% / 2);
}

.camel-spot-1 {
  top: calc(0% + 20% / 2);
  left: calc(39.25% + 21% / 2);
}

.camel-spot-2 {
  top: calc(0% + 20% / 2);
  left: calc(60.25% + 21% / 2);
}

.camel-spot-3 {
  top: calc(0% + 20% / 2);
  left: calc(81.25% + 18.75% / 2);
}

.camel-spot-4 {
  top: calc(20% + 19.5% / 2);
  left: calc(81.25% + 18.75% / 2);
}

.camel-spot-5 {
  top: calc(39.5% + 19.5% / 2);
  left: calc(81.25% + 18.75% / 2);
}

.camel-spot-6 {
  top: calc(59% + 20% / 2);
  left: calc(81.25% + 18.75% / 2);
}

.camel-spot-7 {
  top: calc(79% + 21% / 2);
  left: calc(81% + 19% / 2);
}

.camel-spot-8 {
  top: calc(79% + 21% / 2);
  left: calc(60.5% + 20.5% / 2);
}

.camel-spot-9 {
  top: calc(79% + 21% / 2);
  left: calc(38.5% + 22% / 2);
}

.camel-spot-10 {
  top: calc(79% + 21% / 2);
  left: calc(18.5% + 20% / 2);
}

.camel-spot-11 {
  top: calc(79% + 21% / 2);
  left: calc(0% + 18.5% / 2);
}

.camel-spot-12 {
  top: calc(59.75% + 19.25% / 2);
  left: calc(0% + 18.5% / 2);
}

.camel-spot-13 {
  top: calc(39.75% + 20% / 2);
  left: calc(0% + 18.5% / 2);
}

.camel-spot-14 {
  top: calc(20.5% + 19.25% / 2);
  left: calc(0% + 18.5% / 2);
}

.Camels {
  position: absolute;
}

.LegEndModal {
  position: fixed;
  z-index: 1000;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: rgba(255, 255, 255, 0.95);
  color: black;
  padding: 50px;
  border-radius: 10px;

  transition: opacity 0.5s;  
}

.LegEndModal.hidden {
  pointer-events: none;
  opacity: 0;
}

.LegEndModalInner {
  overflow: auto;
  height: 100%;
}

.LegEndModal .CamelPlacing {
  width: 75%;
  margin: 0 auto;
}

.leg-end-row {
  width: 80%;
  margin: 10px auto;
  display: flex;
  font-size: 24pt;
  justify-content: space-between;
}

.leg-end-row .earnings {
  text-align: right;
}

.CamelPlacingOrdinals {
  display: flex;
}

.CamelPlacingCamels {
  position: relative;
}

.camel-col-placeholder {
  width: 18%;
  padding: 0 1%;
  visibility: hidden;
}

.camel-col {
  width: 18%;
  padding: 0 1%;
  position: absolute;
  top: 0;

  transition: transform 0.5s;
}

.CamelPlacing .col {
  width: 20%;
  text-align: center;
}

.CamelPlacing img {
  width: 100%;
}

.MyTray {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
}

.MyTray .message {
  font-size: 24pt;
  flex: 4;
  padding-right: 30px;
}

.MyTray .cash {
  flex: 1;
  font-size: 24pt;
}

.MyTray .CamelPlacing {
  width: 30%;
  flex: 3;
  font-size: 8pt;
}

.placed-bets {
  height: 11pt;
  font-size: 11pt;
  text-align: center;
}

.MirageOasisDialog, .LongTermBetDialog {
  position: fixed;
  z-index: 1000;
  width: 50%;
  max-height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: rgba(255, 255, 255, 0.95);
  color: black;
  padding: 50px 0;
  border-radius: 10px;

  transition: opacity 0.25s;
}

.MirageOasisDialog.hidden, .LongTermBetDialog.hidden {
  pointer-events: none;
  opacity: 0;
}

.MirageOasisDialogInner, .LongTermBetDialogInner {
  overflow: auto;
  width: 100%;
}

.MirageOasisDialogInner .choices, .LongTermBetDialog .choices {
  display: flex;
  justify-content: space-evenly;
}

.LongTermBetDialog h2 {
  text-align: center;
}

.LongTermBetDialog img {
  width: 15%;
}

.MirageOasisDialogInner img {
  width: 200px; /* idk */
  border-radius: 20px;
  cursor: pointer;
  opacity: 0.9;
  transition: opacity 0.25s;
}

.MirageOasisDialogInner img:hover {
  opacity: 1;
}

.MirageOasisDialogInner .nevermind, .LongTermBetDialog .nevermind {
  width: 30%;
  text-align: center;
  margin: 50px auto 0;

  padding: 20px;
  background-color: #eee;
  border-radius: 10px;
  font-size: 12pt;
  font-weight: bold;
}

.MirageOasisDialogInner .nevermind:hover, .LongTermBetDialog .nevermind:hover {
  background-color: #ddd;
  cursor: pointer;
}

.BackdropFilter {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  backdrop-filter: blur(10px) brightness(50%);
  transition: opacity 0.25s;
}

.BackdropFilter.hidden {
  opacity: 0;
  pointer-events: none;
}

.GameEndScreen {
  position: absolute;
}

.camel-col {
  width: 18%;
  padding: 0 1%;
  position: absolute;
  top: 0;

  transition: transform 0.5s;
}

.CamelPlacing .col {
  width: 20%;
  text-align: center;
}

.CamelPlacing img {
  width: 100%;
}


.LongTermFirstCount {
  position: absolute;
  font-size: 64px;
  font-weight: bold;
  transform: translate3d(-50%, -50%, 75px) rotateX(-90deg);
  transition: top 1s, left 1s, font-size 1s;
  text-shadow: 0px 0px 10px black;

  top: 10%;
  left: 11%;
  color: white;
}

.LongTermLastCount {
  position: absolute;
  font-size: 64px;
  font-weight: bold;
  transform: translate3d(-50%, -50%, 75px) rotateX(-90deg);
  transition: top 1s, left 1s, font-size 1s;
  text-shadow: 0px 0px 10px black;

  top: 10%;
  left: 88%;
  color: white;
}

.slow-reveal {
  transition: opacity 0.5s;
}

.slow-reveal.me {
  color: #afa;
}

.slow-reveal.hidden {
  opacity: 0;
}

.Lasts {
  transition: opacity 0.25s;
}

.Lasts.hidden {
  opacity: 0;
}

.Firsts {
  transition: opacity 0.25s;
}

.Firsts.hidden {
  opacity: 0;
}

.Final {
  transition: opacity 0.25s;
}

.Final.hidden {
  opacity: 0;
}

.GameEndScreen {
  width: 70%;
  left: 50%;
  transform: translateX(-50%);
}

.GameEndScreen h1 {
  text-align: center;
}

.reveal-row {
  display: flex;
  justify-content: space-between;
  font-size: 18pt;
}

.ranking-row {
  display: flex;
  font-size: 36pt;
  justify-content: space-between;
  width: 40%;
  margin: 40px auto;
}

.ranking-row .rank-name {
  flex: 1;
}

.ranking-row .cash {
  flex: 1;
  text-align: right;
}

.Registration {
  text-align: center;
}

.Registration div {
  margin-top: 30vh;
  margin-bottom: 40px;
  font-size: 48pt;
}

.Registration input {
  font-size: 36pt;
  padding: 20px;
  text-align: center;
  width: 300px;
}